import * as actionTypes from '../actions/actionTypes';

import { config } from '../../config';

const initialState = {
	lat: 0,
	lng: 0,
	zoom: parseInt(window.innerWidth > 765 ? config.map.zoom : config.map.mobileZoom),
	layer: 'none',
};

const CHANGE_BY = 150;

const calculateOffset = (zoom) => {
	return CHANGE_BY / Math.pow(zoom, 3);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CAMERA_SET_POSITION:
			return {
				...state,
				lat: action.lat,
				lng: action.lng,
			};

		case actionTypes.CAMERA_INCREASE_LNG:
			return {
				...state,
				lng: state.lng + calculateOffset(state.zoom),
			};
		case actionTypes.CAMERA_DECREASE_LNG:
			return {
				...state,
				lng: state.lng - calculateOffset(state.zoom),
			};
		case actionTypes.CAMERA_INCREASE_LAT:
			return {
				...state,
				lat: state.lat + calculateOffset(state.zoom),
			};
		case actionTypes.CAMERA_DECREASE_LAT:
			return {
				...state,
				lat: state.lat - calculateOffset(state.zoom),
			};
		case actionTypes.CAMERA_INCREASE_ZOOM:
			if (state.zoom + 1 > parseInt(config.map.maxZoom)) {
				return state;
			}
			return {
				...state,
				zoom: state.zoom + 1,
			};
		case actionTypes.CAMERA_DECREASE_ZOOM:
			if (state.zoom - 1 < parseInt(config.map.minZoom)) {
				return state;
			}
			return {
				...state,
				zoom: state.zoom - 1,
			};
		case actionTypes.CAMERA_SET_LAYER:
			return {
				...state,
				layer: action.layer,
			};
		default:
			return state;
	}
};

export default reducer;
