import React from "react";

import FormikInputField from "../../../../components/forms/FormikInputField";
import FormikToggleField from "../../../../components/forms/FormikToggleField";
import DashboardFormsWrapper from "./DashboardFormsWrapper";

const MapParametersForm = () => {
  return (
    <DashboardFormsWrapper>
      <FormikInputField
        name="map.mobileZoom"
        type="number"
        placeholder="Map Mobile Zoom"
      />
      <FormikInputField name="map.zoom" type="number" placeholder="Map Zoom" />
      <FormikInputField
        name="map.maxZoom"
        type="number"
        placeholder="Map Max Zoom"
      />
      <FormikInputField
        name="map.minZoom"
        type="number"
        placeholder="Map Min Zoom"
      />
      <FormikToggleField
        name="map.preferCanvas"
        placeholder="Map Prefer Canvas"
      />
    </DashboardFormsWrapper>
  );
};

export default MapParametersForm;
