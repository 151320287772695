import React, { useState, useEffect } from 'react';

import 'leaflet/dist/leaflet.css';
import './Home.scss';

import { useDispatch, useSelector } from 'react-redux';
import useConfig from '../../../config';
import Header from '../../../components/Header/Header';
import Map from '../../../components/Map/Map';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Compass from '../../../components/Compass/Compass';
import DebugOptions from '../../../components/DebugOptions/DebugOptions';
// import { timeoutCurrentPosition } from '../../../store/actions/marker';
import {
	// setPosition, increaseLng, decreaseLng,
	increaseZoom,
	decreaseZoom,
	nextLayer,
	prevLayer,
} from '../../../store/actions/camera';
import LayoutPicker from '../../../components/LayoutPicker/LayoutPicker';

const Home = () => {
	const dispatch = useDispatch();
	const markers = useSelector((state) => state.marker);

	const [selectedTilemap, setSelectedTilemap] = useState(useConfig('mapAssets.defaultTilemap'));
	const [
		focusedShipIndex,
		// setFocusedShipIndex
	] = useState(0);
	const [
		pin,
		// setPin
	] = useState(true);

	const focusedShip = Object.keys(markers)[focusedShipIndex];

	useEffect(() => {
		const changeZoom = (e) => {
			// if (e.keyCode === 90) {
			// 	if (focusedShipIndex !== null) {
			// 		dispatch(setPosition(markers[focusedShip].lat, markers[focusedShip].lng));
			// 	} else setFocusedShipIndex(0);
			// 	setPin((p) => !p);
			// }

			if (e.keyCode === 38) {
				dispatch(increaseZoom());
			} else if (e.keyCode === 40) {
				dispatch(decreaseZoom());
			} else if (e.keyCode === 39) {
				dispatch(nextLayer());
			} else if (e.keyCode === 37) {
				dispatch(prevLayer());
			}
		};

		window.addEventListener('keydown', changeZoom);
		return () => {
			window.removeEventListener('keydown', changeZoom);
		};
	}, [markers, focusedShip, focusedShipIndex, dispatch]);

	// useEffect(() => {
	// 	const changeBoat = (e) => {
	// 		if (e.keyCode === 39) {
	// 			if (focusedShipIndex + 1 < Object.keys(markers).length) {
	// 				setFocusedShipIndex(focusedShipIndex + 1);
	// 			} else setFocusedShipIndex(0);
	// 		} else if (e.keyCode === 37) {
	// 			if (focusedShipIndex > 0) {
	// 				setFocusedShipIndex(focusedShipIndex - 1);
	// 			} else setFocusedShipIndex(Object.keys(markers).length - 1);
	// 		}
	// 	};

	// 	const changePosition = (e) => {
	// 		if (e.keyCode === 39) {
	// 			dispatch(increaseLng());
	// 		} else if (e.keyCode === 37) {
	// 			dispatch(decreaseLng());
	// 		}
	// 	};

	// 	if (Object.keys(markers).length > 1 && pin) {
	// 		window.addEventListener('keydown', changeBoat);
	// 		return () => window.removeEventListener('keydown', changeBoat);
	// 	} else if (Object.keys(markers).length > 1) {
	// 		window.addEventListener('keydown', changePosition);
	// 		return () => window.removeEventListener('keydown', changePosition);
	// 	}
	// }, [dispatch, focusedShipIndex, markers, pin]);

	// useEffect(() => {
	// 	const setCurrentPosition = setInterval(() => {
	// 		dispatch(timeoutCurrentPosition(markers));
	// 	}, 1000);
	// 	return () => clearInterval(setCurrentPosition);
	// 	//eslint-disable-next-line
	// }, [markers]);

	return (
		<div className="home" style={{ color: useConfig('layout.textColor') }}>
			{/* {Workstation Debuging} */}
			{useConfig('layout.debugOptions') && <DebugOptions setTilemap={setSelectedTilemap} />}
			{/* {Workstation Debuging} */}

			<Header />
			<div className="home__body">
				<div className="home__map">
					<Map
						tilemaps={useConfig('tilemaps')}
						selectedTilemap={selectedTilemap}
						position={[51.505, -0.09]}
						markers={markers}
						focusedShip={pin && focusedShip}
						pin={pin}
					/>
				</div>
				{useConfig('layout.sidebar') && <Sidebar markers={markers} focusedShip={pin && focusedShip} />}
				<LayoutPicker />
				<Compass markers={markers} focusedShip={pin && focusedShip} />
			</div>
		</div>
	);
};

export default Home;
