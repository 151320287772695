import './App.scss';

import { BrowserRouter } from 'react-router-dom';
import Routes from './containers/Routes';

// import cities from './config/cities.json';
import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { baseURL } from './api/axiosInstance';
import { getCurrentPosition, getPreviousPositions } from './store/actions/marker';
import { getConfig } from './config';

function App() {
	const [render, setRender] = useState(false);
	const dispatch = useDispatch();
	const socket = io(baseURL, { transports: ['websocket', 'polling', 'flashsocket'] });

	useEffect(() => {
		(async function () {
			await getConfig();
			setRender(true);
		})();
	}, []);

	useEffect(() => {
		dispatch(getPreviousPositions());
	}, [dispatch]);

	useEffect(() => {
		socket.on('GPS', (data) => {
			dispatch(getCurrentPosition(data));
		});
	}, [dispatch, socket]);

	if (!render) {
		return null;
	}

	return (
		<div className="App">
			<BrowserRouter>
				<Routes />
			</BrowserRouter>
		</div>
	);
}

export default App;
