import React from "react";

import FormikColorField from "../../../../components/forms/FormikColorField";
import FormikToggleField from "../../../../components/forms/FormikToggleField";
import DashboardFormsWrapper from "./DashboardFormsWrapper";
import FormikInputField from "../../../../components/forms/FormikInputField";

const LayoutSetupForm = () => {
  return (
    <DashboardFormsWrapper>
      <FormikColorField
        name="layout.backgroundColor"
        placeholder="Layout Background Color"
      />
      <FormikColorField
        name="layout.textColor"
        placeholder="Layout Text Color"
      />
      <FormikToggleField
        name="layout.automaticChangeLayer"
        placeholder="Automatically Change Layer"
      />
      <FormikInputField
        type="number"
        name="layout.changeLayerInterval"
        placeholder="Change Layer Interval (Miliseconds)"
      />
      <FormikToggleField name="layout.sidebar" placeholder="Display Sidebar" />
      <FormikToggleField
        name="layout.debugOptions"
        placeholder="Display Debug Options"
      />
      <FormikToggleField
        name="layout.distanceTracked"
        placeholder="Display Distance Tracked"
      />
      <FormikToggleField
        name="layout.multimap"
        placeholder="Display Multimap"
      />
      <FormikToggleField
        name="layout.displayCapitals"
        placeholder="Display Capitals"
      />
      <FormikToggleField
        name="layout.capitals"
        placeholder="Display Nearby Location"
      />
    </DashboardFormsWrapper>
  );
};

export default LayoutSetupForm;
