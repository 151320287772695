import React from 'react';

import useConfig from '../../config';

import './DebugOptions.scss';

const DebugOptions = ({ setTilemap }) => {
	const tilemaps = useConfig('tilemaps');
	const icons = useConfig('icons');
	const { defaultTilemap, defaultIcon, focusedIcon } = useConfig('mapAssets');
	const { zoom, maxZoom, minZoom } = useConfig('map');
	const { textColor, backgroundColor, multimap } = useConfig('layout');

	return (
		<section className="debug-options">
			{multimap && <div className="debug-options__field">
				<div className="debug-options__field-label">Tilemap</div>
				<select
					className="debug-options__field-select input"
					onChange={(e) => setTilemap(e.target.value)}
					defaultValue={defaultTilemap}
				>
					{Object.keys(tilemaps).map((tilemap) => (
						<option key={tilemap} value={tilemap}>
							{tilemap}
						</option>
					))}
				</select>
			</div>}
			<div className="debug-options__field">
				<div className="debug-options__field-label">Default Icon</div>
				<select className="debug-options__field-select input" disabled defaultValue={defaultIcon}>
					{Object.keys(icons).map((icon) => (
						<option key={icon} value={icon}>
							{icon}
						</option>
					))}
				</select>
			</div>
			<div className="debug-options__field">
				<div className="debug-options__field-label">Default Focused Icon</div>
				<select className="debug-options__field-select input" disabled defaultValue={focusedIcon}>
					{Object.keys(icons).map((icon) => (
						<option key={icon} value={icon}>
							{icon}
						</option>
					))}
				</select>
			</div>
			<div className="debug-options__field">
				<div className="debug-options__field-label">Background Color</div>
				<input className="debug-options__field-input input" disabled value={backgroundColor} />
			</div>
			<div className="debug-options__field">
				<div className="debug-options__field-label">Text Color</div>
				<input className="debug-options__field-input input" disabled value={textColor} />
			</div>

			<div className="debug-options__field">
				<div className="debug-options__field-label">Max Zoom</div>
				<input className="debug-options__field-input input" disabled value={maxZoom} />
			</div>
			<div className="debug-options__field">
				<div className="debug-options__field-label">Zoom</div>
				<input className="debug-options__field-input input" disabled value={zoom} />
			</div>
			<div className="debug-options__field">
				<div className="debug-options__field-label">Min Zoom</div>
				<input className="debug-options__field-input input" disabled value={minZoom} />
			</div>
		</section>
	);
};

export default DebugOptions;
