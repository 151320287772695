
import React from 'react';

import useConfig from '../../../config';

import { Polyline } from 'react-windy-leaflet';

const MapPolyline = ({ previousPositions }) => {
	const pathOptions = useConfig('path');

	return <Polyline {...pathOptions} positions={previousPositions} />;
};

export default React.memo(MapPolyline)