import useLayers from '../../components/LayoutPicker/useLayers';
import * as actionTypes from './actionTypes';
// import { layers } from '../../components/LayoutPicker/LayoutPicker';

export const setPosition = (lat, lng) => {
	return {
		type: actionTypes.CAMERA_SET_POSITION,
		lat,
		lng,
	};
};

export const increaseZoom = () => {
	return {
		type: actionTypes.CAMERA_INCREASE_ZOOM,
	};
};

export const decreaseZoom = () => {
	return {
		type: actionTypes.CAMERA_DECREASE_ZOOM,
	};
};

export const increaseLat = () => {
	return {
		type: actionTypes.CAMERA_INCREASE_LAT,
	};
};

export const decreaseLat = () => {
	return {
		type: actionTypes.CAMERA_DECREASE_LAT,
	};
};

export const increaseLng = () => {
	return {
		type: actionTypes.CAMERA_INCREASE_LNG,
	};
};

export const decreaseLng = () => {
	return {
		type: actionTypes.CAMERA_DECREASE_LNG,
	};
};

export const setLayer = (layer) => {
	return {
		type: actionTypes.CAMERA_SET_LAYER,
		layer,
	};
};

export const prevLayer = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const layers = useLayers();
	return (dispatch, getState) => {
		let layer = getState().camera.layer;

		const index = layers.findIndex((single) => single.id === layer) - 1;
		if (index === -1) {
			layer = layers[layers.length - 1].id;
		} else layer = layers[index].id;

		dispatch(setLayer(layer));
	};
};

export const nextLayer = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const layers = useLayers();

	return (dispatch, getState) => {
		let layer = getState().camera.layer;

		const index = layers.findIndex((single) => single.id === layer) + 1;
		if (index === layers.length) {
			layer = layers[0].id;
		} else layer = layers[index].id;

		dispatch(setLayer(layer));
	};
};
