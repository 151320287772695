import React, { useEffect } from 'react';

import './LayoutPicker.scss';

import { useSelector, useDispatch } from 'react-redux';
import { nextLayer, setLayer } from '../../store/actions/camera';
import useConfig from '../../config';
import useLayers from './useLayers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LayoutPicker = () => {
	const dispatch = useDispatch();
	const layers = useLayers();
	const { automaticChangeLayer, changeLayerInterval } = useConfig('layout');
	const layer = useSelector((state) => state.camera.layer);

	const currentLayer = layers.find(({ id }) => id === layer);

	useEffect(() => {
		if (automaticChangeLayer) {
			const layoutSwitcher = setInterval(() => {
				dispatch(nextLayer());
			}, changeLayerInterval);

			return () => clearInterval(layoutSwitcher);
		}
	}, [automaticChangeLayer, changeLayerInterval, dispatch]);

	if (layers.length <= 1) {
		return null;
	}

	return (
		<>
			<div className="layout-picker">
				{layers.map(({ id, icon, title }) => (
					<div className={`layout-picker__info ${layer === id ? 'active' : ''}`} onClick={() => dispatch(setLayer(id))}>
						<FontAwesomeIcon icon={icon} />
						<p>{title}</p>
					</div>
				))}
			</div>

			<div className="layout-picker-mobile">
				<p>{currentLayer.title}</p>
				<div className="layout-picker-icon">
					<FontAwesomeIcon icon={currentLayer.icon} />
				</div>
			</div>
		</>
	);
};

export default LayoutPicker;
