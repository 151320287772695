import React from 'react';

import './Header.scss';
import useConfig from '../../config';

const Header = () => {
	const { applicationName, applicationDescription } = useConfig('layout');
	return (
		<div className="header">
			<div className="header__icon"></div>
			<div className="header__name">
				<h1>{applicationName}</h1>
				<p>{applicationDescription}</p>
			</div>
		</div>
	);
};

export default React.memo(Header);
