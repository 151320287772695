import React from 'react';

import FormikToggleField from '../../../../components/forms/FormikToggleField';
import DashboardFormsWrapper from './DashboardFormsWrapper';

const LayersForm = () => {
	return (
		<DashboardFormsWrapper>
			<FormikToggleField name="layers.wind" placeholder="Wind Layer" />
			<FormikToggleField name="layers.temperature" placeholder="Temperature Layer" />
			<FormikToggleField name="layers.rain" placeholder="Rain & Thunder Layer" />
			<FormikToggleField name="layers.clouds" placeholder="Clouds Layer" />
		</DashboardFormsWrapper>
	);
};

export default LayersForm;
