import React from "react";

// import LogoSVG from "assets/svgs/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const { pathname } = location;

  return (
    <div
      className={`flex flex-col z-40 sticky left-auto top-0 translate-x-0 transform h-screen overflow-y-auto no-scrollbar w-64 shrink-0 bg-layout transition-all duration-200 ease-in-out`}
    >
      <div className="flex justify-center pt-2 pb-6 border-b-2 m-4">
        {/* <img src={LogoSVG} alt="Artheusa Logo" /> */}
      </div>

      <ul className="mt-7">
        <li className="bg-[#E9F0F4] h-24 flex border-l-4 border-primary pl-6 items-center text-button-hover font-semibold cursor-pointer">
          <FontAwesomeIcon icon={faLocationDot} className="mr-3" />
          <span>Map Parameters</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
