import React from 'react';
import { Field } from 'formik';

const FormikInputField = ({ name, placeholder }) => {
	return (
		<div>
			<label htmlFor={`${name}-toggle`} className="inline-flex relative items-center cursor-pointer">
				<Field name={name} type="checkbox" id={`${name}-toggle`} className="sr-only peer" />
				<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-lime-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#5FC763]"></div>
				<span className="ml-3 text-sm font-medium text-gray-900 ">{placeholder}</span>
			</label>
		</div>
	);
};

export default FormikInputField;
