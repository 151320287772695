import React, { useEffect } from 'react';

import { useLeaflet } from 'react-windy-leaflet';
import { useSelector } from 'react-redux';

const MapEvents = ({ markerPosition, pin }) => {
	const camera = useSelector((state) => state.camera);
	const { map } = useLeaflet();

	useEffect(() => {
		if (window.innerWidth > 765) {
			map._handlers.forEach(function (handler) {
				handler.disable();
			});
		}
	}, [map, pin]);

	useEffect(() => {
		if (markerPosition && pin) {
			map.setView(markerPosition, camera.zoom);
		} else map.setView([camera.lat, camera.lng], camera.zoom);
	}, [map, markerPosition, pin, camera]);

	return null;
};

export default React.memo(MapEvents);
