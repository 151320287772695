import React from "react";

// import { faArrowRightFromBracket, faGear, faHouse, faNoteSticky } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();

  const handleMap = () => {
    history.push("/");
  };

  return (
    <div className="bg-layout h-28 flex ">
      <div className="border-l-2 px-6 my-6 flex w-full items-center justify-between">
        <div className="text-xl font-bold">Map Parameters</div>

        <div className="flex items-center h-full">
          {/* <button
            className="flex justify-center py-2 px-8 border border-transparent shadow-sm transition-colors font-medium text-white bg-button hover:bg-button-hover"
          >
            Important Actions
          </button> */}
          {/* <div className="border-l-2 h-full mx-8"></div> */}
          <button
            onClick={handleMap}
            className="flex justify-center py-2 px-8 border border-transparent shadow-sm transition-colors font-medium text-white bg-button hover:bg-button-hover"
          >
            Map
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
