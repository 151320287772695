import React from 'react';

import useConfig from '../../../config';

import { Marker } from 'react-windy-leaflet';
import Icons from '../Icons/Icons';

const Capital = ({ position }) => {
	const capitalPointIcon = useConfig('mapAssets.capitalIcon');
	const icon = Icons[capitalPointIcon];

	return <Marker key={position.lat + position.lng + '_capital'} icon={icon} position={position}></Marker>;
};

export default React.memo(Capital);