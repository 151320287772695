import React from "react";

import FormikInputField from "../../../../components/forms/FormikInputField";
import DashboardFormsWrapper from "./DashboardFormsWrapper";

const SidebarSetupForm = () => {
  return (
    <DashboardFormsWrapper>
      <FormikInputField
        name="sidebar.boat-position"
        placeholder="Boat Position Icon"
        disabled
      />
      <FormikInputField
        name="sidebar.boat-speed"
        placeholder="Boat Speed Icon"
        disabled
      />
      <FormikInputField
        name="sidebar.nearby-capital"
        placeholder="Nearby Capital Icon"
        disabled
      />
    </DashboardFormsWrapper>
  );
};

export default SidebarSetupForm;
