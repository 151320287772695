import React from 'react';
import { Field, useField } from 'formik';
import FieldErrorFeedbackFormatter from '../FieldErrorFeedbackFormatter';

const FormikColorField = ({ className, name, type = 'text', placeholder, helperText, disabled = false }) => {
	const [field, meta] = useField(name);

	let helperTextValue;
	let additionalClass = '';

	if (meta.touched && meta.error) {
		helperTextValue = meta.touched && meta.error ? <FieldErrorFeedbackFormatter error={meta.error} /> : helperText;
		additionalClass += 'border-2 border-red-500';
	}

	if (disabled) {
		additionalClass += 'text-[#C5C5C5] border-[#EDEDED] bg-[#FBFBFB]';
	}

	const { value } = field;
	const color = value ? value : '#FFF';

	return (
		<div>
			{placeholder && <div className="text-xs font-bold mb-3">{placeholder}</div>}
			<div className="flex h-9">
				<Field
					name={name}
					type={type}
					placeholder={placeholder}
					disabled={disabled}
					className={`appearance-none block w-full rounded-sm px-4 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-800 focus:border-primary sm:text-sm ${className} ${additionalClass}`}
				/>
				{helperTextValue && <p className="text-xs mt-1 text-red-600">{helperTextValue}</p>}
				<div className="min-w-[50px] h-full border border-gray-300 ml-1" style={{ backgroundColor: color }}></div>
			</div>
		</div>
	);
};

export default FormikColorField;
