import React from 'react';

import './Compass.scss';

const Compass = ({ markers, focusedShip }) => {
	const degress = markers[focusedShip]?.compassDirection?.degrees;


	return (
		<div className="compass">
			<img src="/images/icons/compass.svg" className="compass__main" alt="Compass" />
			<img src="/images/icons/compass-needle.svg" className="compass__needle" alt="Compass Needle" style={{transform:`translate(-50%,-50%) rotate(${degress}deg)`}}/>
		</div>
	);
};

export default Compass;
