import React from 'react';

import { Form, Formik } from 'formik';
import Layout from './Layout/Layout';
import LabelsSetupForm from './Forms/LabelsSetupForm';
import PathParametersForm from './Forms/PathParametersForm';
import MapAssetsForm from './Forms/MapAssetsForm';
import MapParametersForm from './Forms/MapParametersForm';
import LayoutSetupForm from './Forms/LayoutSetupForm';
import LayersForm from './Forms/LayersForm';
import { useAdmin } from './useAdmin';
import SidebarSetupForm from './Forms/SidebarSetupForm';

import axios from '../../../api/axiosInstance';
// import { postBuild as postBuildAPI } from "api/build";

const Admin = () => {
	const { initialValues } = useAdmin();

	const handlePostBuild = async (values) => {
		console.log(values);
    axios.post("/api/config",values)
	};

	return (
		<Layout>
			<Formik initialValues={initialValues} onSubmit={handlePostBuild}>
				<Form>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Layout Setup</div>
						<LayoutSetupForm />
					</div>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Labels Setup</div>
						<LabelsSetupForm />
					</div>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Map Parameters</div>
						<MapParametersForm />
					</div>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Sidebar Setup</div>
						<SidebarSetupForm />
					</div>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Path Parameters</div>
						<PathParametersForm />
					</div>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Map Assets</div>
						<MapAssetsForm />
					</div>
					<div className="flex border-b-2 mt-16 pb-9">
						<div className="font-bold min-w-[280px]">Layers</div>
						<LayersForm />
					</div>

					<div className="flex mt-16 pb-9">
						<div className="min-w-[280px]"></div>
						<div className="flex items-center">
							<button
								type="submit"
								className="flex justify-center py-2 px-12 border border-transparent shadow-sm transition-colors font-medium text-white bg-button hover:bg-button-hover mr-3"
							>
								{/* {isBuildLoading ? 'Updating...' : 'Update'} */}
								Update
							</button>
							<button className="flex justify-center py-2 px-12 border border-transparent font-medium">Reset</button>
						</div>
					</div>
				</Form>
			</Formik>
		</Layout>
	);
};

export default Admin;
