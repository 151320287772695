import React from 'react';

import useConfig from '../../../config';

import { Marker, Tooltip } from 'react-windy-leaflet';
import Icons from '../Icons/Icons';

const MarkerWrapper = React.memo(({ position, focused }) => {
	const { defaultIcon, focusedIcon } = useConfig('mapAssets');

	const icon = focused ? Icons[focusedIcon] : Icons[defaultIcon];

	return (
		<>
			<Marker key={position.lat + position.lng} icon={icon} position={position} zIndexOffset={focused ? 1000 : 1}>
				<Tooltip  direction="top" offset={[0, -70]} opacity={1} permanent>{useConfig("layout.boatName")}</Tooltip>
			</Marker>
		</>
	);
});

export default React.memo(MarkerWrapper);
