export const MARKER_CHANGE_POSITION = 'MARKER_CHANGE_POSITION';
export const MARKER_INCREASE_DISTANCE = 'MARKER_INCREASE_DISTANCE';
export const MARKER_SET_SPEED = 'MARKER_SET_SPEED';
export const MARKER_RESET_STARTING_POINT = 'MARKER_RESET_STARTING_POINT';
export const MARKER_CHANGE_COMPASS_DIRECTION = 'MARKER_CHANGE_COMPASS_DIRECTION';
export const MARKER_SET_PREVIOUS_POSITIONS = 'MARKER_SET_PREVIOUS_POSITIONS'

export const CAMERA_SET_POSITION = 'CAMERA_SET_POSITION';
export const CAMERA_INCREASE_LNG = 'CAMERA_INCREASE_LNG';
export const CAMERA_DECREASE_LNG = 'CAMERA_DECREASE_LNG';
export const CAMERA_INCREASE_LAT = 'CAMERA_INCREASE_LAT';
export const CAMERA_DECREASE_LAT = 'CAMERA_DECREASE_LAT';
export const CAMERA_INCREASE_ZOOM = 'CAMERA_INCREASE_ZOOM';
export const CAMERA_DECREASE_ZOOM = 'CAMERA_DECREASE_ZOOM';
export const CAMERA_SET_LAYER = 'CAMERA_SET_LAYER';
