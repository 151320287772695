export const roundNumber = (n) => {
	if (n < 1000) {
		return n;
	} else if (n < 1_000_000) {
		return Math.round(n / 1000) + 'K';
	} else return Math.round(n / 10_000) / 100 + 'M';
};

export const convertGpsCordinates = (n) => {
	return [0 | n, '° ', 0 | (((n < 0 ? (n = -n) : n) % 1) * 60), "' ", 0 | (((n * 60) % 1) * 60), '"'].join('');
};

export const calculateDistance = (marker1, marker2) => {
	let { lat: lat1, lng: lng1 } = marker1;
	let { lat: lat2, lng: lng2 } = marker2;

	// The math module contains a function
	// named toRadians which converts from
	// degrees to radians.
	lng1 = (lng1 * Math.PI) / 180;
	lng2 = (lng2 * Math.PI) / 180;
	lat1 = (lat1 * Math.PI) / 180;
	lat2 = (lat2 * Math.PI) / 180;

	// Haversine formula
	let dlon = lng2 - lng1;
	let dlat = lat2 - lat1;
	let a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

	let c = 2 * Math.asin(Math.sqrt(a));

	// Radius of earth in kilometers.
	const EARTH_RADIUS_KILOMETERS = 6371;

	return c * EARTH_RADIUS_KILOMETERS;
};

export const findCompassDirection = (marker1, marker2, degrees = true) => {
	let { lat: lat1, lng: lng1 } = marker1;
	let { lat: lat2, lng: lng2 } = marker2;

	let dlon = lng2 - lng1;

	var y = Math.sin(dlon) * Math.cos(lat2);
	var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dlon);
	var brng = (Math.atan2(y, x) * 180) / Math.PI;

	var bearings = ['NORTH-EAST', 'EAST', 'SOUTH-EAST', 'SOUTH', 'SOUTH-WEST', 'WEST', 'NORTH-WEST', 'NORTH'];

	var index = brng - 22.5;
	if (index < 0) index += 360;
	index = parseInt(index / 45);

	const directionCode = bearings[index];

	if (!degrees) {
		return directionCode;
	}

	return { code: directionCode, degrees: brng };
};
