// import { isObject } from 'utils/isObject';

const FieldErrorFeedbackFormatter = ({ error }) => {
	switch (true) {
		case typeof error === 'string':
			return error;

		// case isObject(error) && Object.values(error).length > 0:
		// 	const firstError = Object.values(error)[0];
		// 	return firstError;

		default:
			return 'Error Occured!';
	}
};

export default FieldErrorFeedbackFormatter;
