import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Admin from './views/Admin/Admin';
import Home from './views/Home/Home';

const RoutesComponent = () => {
	return (
		<Switch>
			<Route path="/admin" component={Admin} />
			<Route path="/" component={Home} />
			<Redirect to="/" />
		</Switch>
	);
};

export default RoutesComponent;
