import React from 'react';

import useConfig from '../../../config';

import { Marker } from 'react-windy-leaflet';
import Icons from '../Icons/Icons';

const StartingPoint = ({ position }) => {
	const startingPointIcon = useConfig('mapAssets.startingPointIcon');
	const icon = Icons[startingPointIcon];

	return <Marker key={position.lat + position.lng + '_starting'} icon={icon} position={position}></Marker>;
};

export default React.memo(StartingPoint);