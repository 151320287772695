import axios from 'axios';

// const { HOST, PORT } = config.backend;

// const baseURL = 'http://localhost:8080'
// const baseURL = `http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;
const baseURL = '/_backend';

const instance = axios.create({
	baseURL: `${baseURL}`,
});

export { baseURL };

export default instance;
