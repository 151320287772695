import * as actionTypes from '../actions/actionTypes';

const initialState = {
	
	// 'Small Boat': {
	// 	lat: 48.353669,
	// 	lng: 16.259949,
	// 	distance: 0,
	// 	speed: 0,
	// 	previousPositions: [[48.353669, 16.259949]],
	// 	compassDirection: {
	// 		code: 'WEST',
	// 		degrees: 0,
	// 	},
	// 	nearestCapital: {
	// 		country: "Austria",
	// 		name: "Vienna",
	// 		image: "/images/cities/vienna.png",
	// 		population: "1752286",
	// 		lat: "47.5",
	// 		lng: "19.083333",
	// 		code: "HU",
	// 		continent: "Europe",
	// 		distance: 160,
	// 		direction: 'NORTH',
	// 	},
	// },
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.MARKER_SET_PREVIOUS_POSITIONS:
			return {
				...state,
				[action.name]: {
					...state[action.name],
					previousPositions: action.positions,
				},
			};
		case actionTypes.MARKER_CHANGE_POSITION:
			if (state[action.name]?.previousPositions) {
				return {
					...state,
					[action.name]: {
						...state[action.name],
						lat: action.lat,
						lng: action.lng,
						previousPositions: [...state[action.name].previousPositions, [action.lat, action.lng]],
						nearestCapital: action.nearestCapital,
					},
				};
			}
			return {
				...state,
				[action.name]: {
					...state[action.name],
					lat: action.lat,
					lng: action.lng,
					previousPositions: [[action.lat, action.lng]],
					nearestCapital: action.nearestCapital,
				},
			};
		case actionTypes.MARKER_INCREASE_DISTANCE:
			return {
				...state,
				[action.name]: {
					...state[action.name],
					distance: state[action.name]?.distance + action.distance,
				},
			};
		case actionTypes.MARKER_SET_SPEED:
			return {
				...state,
				[action.name]: {
					...state[action.name],
					speed: action.speed,
				},
			};
		case actionTypes.MARKER_RESET_STARTING_POINT:
			return {
				...state,
				[action.name]: {
					...state[action.name],
					previousPositions: [[state[action.name].lat, state[action.name].lng]],
				},
			};
		case actionTypes.MARKER_CHANGE_COMPASS_DIRECTION:
			return {
				...state,
				[action.name]: {
					...state[action.name],
					compassDirection: action.compassDirection,
				},
			};
		default:
			return state;
	}
};

export default reducer;
