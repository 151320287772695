import axios from '../api/axiosInstance';
import defaultConfig from './config.json';

let isDefaultConfig = true;
let config = defaultConfig;

const getConfig = async () => {
	if (isDefaultConfig) {
		config = (await axios.get('/api/config')).data;
		isDefaultConfig = false;
	}
};

const useConfig = (property) => {
	if (!property) {
		return config;
	}

	const splitProperty = property.split('.');

	let value = null;

	for (let singleProperty of splitProperty) {
		if (value) {
			value = value[singleProperty];
		} else value = config[singleProperty];

		if (!value) {
			break;
		}
	}

	return value;
};

export { config, getConfig, isDefaultConfig };
export default useConfig;
