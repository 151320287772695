
import { faMap, faWind, faSun, faCloudBolt, faCloud } from '@fortawesome/free-solid-svg-icons';
import useConfig from '../../config';

export default function useLayers() {
    const { wind, temperature, rain, clouds } = useConfig("layers");

    let layers = [
        {
            id: 'none',
            icon: faMap,
            title: 'Default',
        },

        // {
        // 	id: 'waves',
        // 	icon: faWater,
        // 	title: 'Waves',
        // },
    ];

    if (wind) {
        layers.push({
            id: 'wind',
            icon: faWind,
            title: 'Wind',

        })
    }
    if (temperature) {
        layers.push({
            id: 'temp',
            icon: faSun,
            title: 'Temperature',
        })
    }
    if (rain) {
        layers.push({
            id: 'rain',
            icon: faCloudBolt,
            title: 'Rain & Thunder',
        })
    }
    if (clouds) {
        layers.push({ id: 'clouds', icon: faCloud, title: 'Clouds' })
    }

    return layers;
}