import React from "react";

import FormikInputField from "../../../../components/forms/FormikInputField";
import DashboardFormsWrapper from "./DashboardFormsWrapper";

const MapAssetsForm = () => {
  return (
    <DashboardFormsWrapper>
      <FormikInputField
        name="mapAssets.defaultTilemap"
        placeholder="Default Map Tilemap"
        disabled
      />
      <FormikInputField
        name="mapAssets.defaultIcon"
        placeholder="Default Map Icon"
        disabled
      />
      <FormikInputField
        name="mapAssets.focusedIcon"
        placeholder="Focused Icon"
        disabled
      />
      <FormikInputField
        name="mapAssets.startingPointIcon"
        placeholder="Starting Point Icon"
        disabled
      />
      <FormikInputField
        name="mapAssets.capitalIcon"
        placeholder="Capital Icon"
        disabled
      />
    </DashboardFormsWrapper>
  );
};

export default MapAssetsForm;
