import React from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
	return (
		<div className="flex min-h-screen">
			<Sidebar />

			<div className="scroll-top relative flex flex-col flex-1 sm:overflow-y-visible overflow-y-auto overflow-x-hidden">
				<div className="min-h-screen">
					<Header />
					<div className="container mx-auto px-6">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Layout;
