import React from 'react';

import useConfig from '../../config';
import cities from '../../config/cities.json';

import Events from './Events/Events';
import Marker from './Marker/Marker';
import Polyline from './Polyline/Polyline';
import StartingPoint from './StartingPoint/StartingPoint';
import Capital from './Capital/Capital';
import { useSelector } from 'react-redux';
import { Map, LayersControl, TileLayer } from 'react-windy-leaflet';

// const { BaseLayer } = LayersControl;

const Maps = ({ selectedTilemap, markers, focusedShip, pin }) => {
	const tilemaps = useConfig('tilemaps') ?? {};
	const mapConfig = useConfig('map') ?? {};
	const layer = useSelector((state) => state.camera.layer);

	let startingPoints = Object.values(markers).map((marker) => {
		if (marker?.previousPositions?.length) {
			return marker.previousPositions[0];
		}
		return null;
	});
	startingPoints = startingPoints.filter(Boolean);

	const { BaseLayer } = LayersControl;

	return (
		<div>
			<Map
				windyKey={mapConfig.windyKeyMap}
				windyLabels={false}
				windyControls={false}
				overlay={layer ?? 'wind'}
				overlayOpacity={0.8}
				particlesAnim={false}
				// removeWindyLayers
				// pickerPosition={state.pickerOpen ? [state.pickerLat, state.pickerLng] : null}
				// onPickerOpened={(latLng) => console.log('Picker Opened', latLng)}
				onPickerMoved={(latLng) => {
					this.setState({
						pickerLat: latLng.lat,
						pickerLng: latLng.lon,
					});
				}}
				{...mapConfig}
				mapElements={
					<>
						<LayersControl>
							{Object.entries(tilemaps).map((tilemap) => (
								<BaseLayer key={tilemap[0]} name={tilemap[0]} checked={tilemap[0] === selectedTilemap}>
									<TileLayer attribution={tilemap[1]?.attribution} url={tilemap[1]?.url} />
								</BaseLayer>
							))}
						</LayersControl>

						{markers &&
							Object.entries(markers).map((marker) => {
								const focused = focusedShip === marker[0];
								return <Marker key={marker[0]} position={marker[1]} focused={focused}></Marker>;
							})}

						{startingPoints &&
							startingPoints.length &&
							startingPoints.map((startingPoint) => (
								<StartingPoint key={startingPoint.lat + startingPoint.lng + '_starting'} position={startingPoint} />
							))}

						{useConfig('layout.capitals') &&
							cities &&
							cities.map((city) => <Capital key={cities.name} position={[city.lat, city.lng]} />)}

						{useConfig('layout.distanceTracked') &&
							markers &&
							Object.values(markers).map((marker, index) => (
								<Polyline key={index} previousPositions={marker.previousPositions} />
							))}

						<Events pin={pin} markerPosition={markers[focusedShip]} />
					</>
				}
			/>
		</div>
	);
};

export default React.memo(Maps);
