import { config } from '../../../config';
import { Icon } from 'leaflet';

const Icons = {};

const configIcons = config['icons'];

Object.entries(configIcons).forEach((icon) => {
	Icons[icon[0]] = new Icon({
		...icon[1],
	});
});

export default Icons;
