import * as actionTypes from './actionTypes';

import capitals from '../../config/cities.json';
import { calculateDistance, findCompassDirection } from '../../helpers';
import axios from '../../api/axiosInstance';

export const setPreviousPositions = (name, positions) => {
	let updatesPositions = positions.filter((pos) => pos[0] !== 0 && pos[1] !== 0);
	return {
		type: actionTypes.MARKER_SET_PREVIOUS_POSITIONS,
		name,
		positions: updatesPositions,
	};
};

export const setLatLng = (name, lat, lng) => {
	if (!lat || !lng) return;
	const nearestCapital = findNearestCapital({ lat, lng });
	return {
		type: actionTypes.MARKER_CHANGE_POSITION,
		name,
		lat,
		lng,
		nearestCapital,
	};
};

export const increaseDistance = (name, distance) => {
	return {
		type: actionTypes.MARKER_INCREASE_DISTANCE,
		name,
		distance,
	};
};

export const setSpeed = (name, speed) => {
	return {
		type: actionTypes.MARKER_SET_SPEED,
		name,
		speed,
	};
};

export const setCompassDirection = (name, compassDirection) => {
	return {
		type: actionTypes.MARKER_CHANGE_COMPASS_DIRECTION,
		name,
		compassDirection,
	};
};

export const resetStartingPoint = (name) => {
	return {
		type: actionTypes.MARKER_RESET_STARTING_POINT,
		name,
	};
};

export const findNearestCapital = (marker) => {
	let lowestDistance;
	let capitalName;

	capitals.forEach((capital) => {
		let currentDistance = calculateDistance(marker, capital);
		if (!lowestDistance || currentDistance < lowestDistance) {
			lowestDistance = currentDistance;
			capitalName = capital.name;
		}
	});

	const nearestCapital = capitals.find((capital) => capital.name === capitalName);
	const direction = findCompassDirection(marker, nearestCapital, false);

	return { distance: lowestDistance, direction, ...nearestCapital };
};

export const getPreviousPositions = () => {
	return (dispatch) => {
		axios
			.get('/api/position')
			.then(({ data }) => {
				if (data.length) {
					const latest = data.pop();
					const lat = latest[0];
					const lng = latest[1];

					dispatch(setLatLng('marker', lat, lng));
					dispatch(setSpeed('marker', 0));
					dispatch(setPreviousPositions('marker', data));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

// This will be used when we have real API
export const getCurrentPosition = (newMarker) => {
	const { lat, lng, speed } = newMarker;

	return (dispatch, getState) => {
		const oldMarkers = getState().marker;
		if (oldMarkers['marker']) {
			const distance = calculateDistance(oldMarkers['marker'], newMarker);
			const compassDirection = findCompassDirection(oldMarkers['marker'], newMarker);

			dispatch(setCompassDirection('marker', compassDirection));
			dispatch(increaseDistance('marker', distance));
		}

		dispatch(setLatLng('marker', lat, lng));
		dispatch(setSpeed('marker', speed));
	};
};

// This should be only used for Testing purpose

// export const timeoutCurrentPosition = (markers) => {
// 	return (dispatch) => {
// 		Object.entries(markers).map((marker) => {
// 			const offset = 50;
// 			const latDecrease = (Math.random() * 0.1) / offset;
// 			const lngIncrease = (Math.random() * 0.05) / offset;

// 			const newMarkerValues = {
// 				lat: marker[1].lat - latDecrease,
// 				lng: marker[1].lng + lngIncrease,
// 			};

// 			const distance = calculateDistance(marker[1], newMarkerValues);
// 			const compassDirection = findCompassDirection(marker[1], newMarkerValues);

// 			const speed = Math.floor(Math.random() * 10) + 20;

// 			// dispatch(setPreviousPositions(marker[0]));
// 			dispatch(setCompassDirection(marker[0], compassDirection));
// 			dispatch(setSpeed(marker[0], speed));
// 			dispatch(increaseDistance(marker[0], distance));
// 			return dispatch(setLatLng(marker[0], newMarkerValues.lat, newMarkerValues.lng));
// 		});
// 	};
// };
