import React from "react";

import FormikInputField from "../../../../components/forms/FormikInputField";
import DashboardFormsWrapper from "./DashboardFormsWrapper";

const PathParametersForm = () => {
  return (
    <DashboardFormsWrapper>
      <FormikInputField name="path.weight" placeholder="Path Weight" />
      {/* TODO Add color */}
      <FormikInputField name="path.color" placeholder="Path Color" />
      <FormikInputField name="path.dashArray" placeholder="Path Dash Array" />
    </DashboardFormsWrapper>
  );
};

export default PathParametersForm;
