import React, { useEffect, useMemo, useState } from 'react';

import './Sidebar.scss';

import { useSelector } from 'react-redux';
import useConfig from '../../config';
import { roundNumber, convertGpsCordinates } from '../../helpers/';
import axios from 'axios';

import dayjs from 'dayjs';

const findCurrentIndex = (timestamps) => {
	let index = 0;
	timestamps?.forEach((ts, i) => {
		if (dayjs(ts) <= dayjs()) {
			index = i;
		}
	});

	return index;
};
const kelvinToCelsius = (kelvin) => Number(kelvin - 273.15).toFixed(0);

const Sidebar = ({ markers, focusedShip }) => {
	const [forecastData, setForecastData] = useState(null);
	const layer = useSelector((state) => state.camera.layer);

	const camera = useSelector((state) => state.camera);
	const shipInfo = markers[focusedShip];

	let lat, lng, speed, direction, nearestCapital;

	if (shipInfo) {
		lat = shipInfo.lat;
		lng = shipInfo.lng;
		nearestCapital = shipInfo.nearestCapital;
		// distance = Math.floor(shipInfo.distance);
		speed = Math.floor(shipInfo.speed);
		direction = shipInfo?.compassDirection?.code;
	} else {
		lat = camera.lat;
		lng = camera.lng;
		// zoom = camera.zoom;
	}

	const {
		displayCapitals,
		backgroundColor,
		nearbyCapital,
		capitalName,
		population,
		latitude,
		longitude,
		boatSpeed,
		currentSpeed,
		avarageSpeed,
	} = useConfig('layout');
	const sidebar = useConfig('sidebar');
	const mapConfig = useConfig('map') ?? {};

	useEffect(() => {
		axios
			.post('https://api.windy.com/api/point-forecast/v2', {
				lat,
				lon: lng,
				model: 'gfs',
				parameters: ['temp', 'pressure', 'windGust', 'lclouds'],
				levels: ['surface'],
				key: mapConfig.windyKeyPoint,
			})
			.then((res) => {
				setForecastData(res.data);
			});
	}, [lat, lng, mapConfig.windyKeyPoint]);

	const currentForecastIndex = useMemo(() => {
		if (forecastData) {
			return findCurrentIndex(forecastData.ts);
		}
		return 0;
	}, [forecastData]);

	return (
		<div
			className="sidebar"
			onClick={(e) => {
				document.querySelector('.sidebar').classList.toggle('open');
			}}
		>
			{layer === 'none' && (
				<>
					{/* POSITION BLOCK */}
					<div
						className="sidebar__block"
						style={{
							backgroundColor: backgroundColor,
						}}
					>
						<div className="sidebar__header">
							<p className="sidebar__name">
								<img src={sidebar['boat-position']} alt="Boat Position" />
								{focusedShip ? 'Boat Position' : 'Free Camera'}
							</p>
							<p className="sidebar__info">{direction}</p>
						</div>
						<div className="sidebar__body">
							<div className="sidebar__split">
								<p className="sidebar__split-label">{latitude}</p>
								<p className="sidebar__split-value">{convertGpsCordinates(lat)}</p>
							</div>
							<div className="sidebar__split">
								<p className="sidebar__split-label">{longitude}</p>
								<p className="sidebar__split-value">{convertGpsCordinates(lng)}</p>
							</div>
						</div>
					</div>

					{/* SPEED BLOCK */}
					<div
						className="sidebar__block"
						style={{
							backgroundColor: backgroundColor,
						}}
					>
						<div className="sidebar__header">
							<p className="sidebar__name">
								<img src={sidebar['boat-speed']} alt="Boat Speed" />
								{boatSpeed}
							</p>
						</div>
						<div className="sidebar__body">
							<div className="sidebar__split">
								<p className="sidebar__split-label">{currentSpeed}</p>
								<p className="sidebar__split-value">{speed} km/h</p>
							</div>
							<div className="sidebar__split">
								<p className="sidebar__split-label">{avarageSpeed}</p>
								<p className="sidebar__split-value">{speed} km/h</p>
							</div>
						</div>
					</div>

					{/* CAPITAL BLOCK */}
					{displayCapitals && (
						<div
							className="sidebar__block"
							style={{
								backgroundColor: backgroundColor,
							}}
						>
							<div className="sidebar__header">
								<p className="sidebar__name">
									<img src={sidebar['nearby-capital']} alt="Nearby Capital" />
									{nearbyCapital}
								</p>
								<p className="sidebar__info">
									{nearestCapital?.distance && Math.floor(nearestCapital?.distance)} KM {nearestCapital?.direction}
								</p>
							</div>
							<div className="sidebar__image">
								<img src={nearestCapital?.image || '/images/cities/placeholder.png'} alt="City" />
							</div>

							<div className="sidebar__body">
								<div className="sidebar__split">
									<p className="sidebar__split-label">{capitalName}</p>
									<p className="sidebar__split-value">{nearestCapital?.name}</p>
								</div>
								<div className="sidebar__split">
									<p className="sidebar__split-label">{population}</p>
									<p className="sidebar__split-value">
										{nearestCapital?.population && roundNumber(nearestCapital?.population)}
									</p>
								</div>
							</div>
						</div>
					)}
				</>
			)}
			{/* FORECAST BLOCK */}
			{layer !== 'none' && (
				<>
					<div
						className="sidebar__block"
						style={{
							backgroundColor: backgroundColor,
						}}
					>
						<div className="sidebar__header">
							<p className="sidebar__name">
								<img src={'/images/icons/parameters.svg'} alt="Nearby Capital" />
								Forecast
							</p>
							<p className="sidebar__info"></p>
						</div>

						<div className="sidebar__body">
							<div className="sidebar__split">
								<p className="sidebar__split-label">Temperature</p>
								<p className="sidebar__split-value">
									{kelvinToCelsius(forecastData?.['temp-surface'][currentForecastIndex])} °C
								</p>
							</div>
							<div className="sidebar__split">
								<p className="sidebar__split-label">Pressure</p>
								<p className="sidebar__split-value">
									{Number(forecastData?.['pressure-surface'][currentForecastIndex]).toFixed(0)}{' '}
									{forecastData?.units?.['pressure-surface']}
								</p>
							</div>
						</div>
						<div className="sidebar__body">
							<div className="sidebar__split">
								<p className="sidebar__split-label">Wind Gust</p>
								<p className="sidebar__split-value">
									{Number(forecastData?.['gust-surface'][currentForecastIndex]).toFixed(0)} m/s
								</p>
							</div>
							<div className="sidebar__split">
								<p className="sidebar__split-label">Cloud Surface</p>
								<p className="sidebar__split-value">
									{Number(forecastData?.['lclouds-surface'][currentForecastIndex]).toFixed(0)}{' '}
									{forecastData?.units?.['lclouds-surface']}
								</p>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default React.memo(Sidebar);
