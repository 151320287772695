import React from "react";

import FormikInputField from "../../../../components/forms/FormikInputField";
import DashboardFormsWrapper from "./DashboardFormsWrapper";

const LabelsSetupForm = () => {
  return (
    <DashboardFormsWrapper>
      <FormikInputField name="layout.boatName" placeholder="Boat Name" />
      <FormikInputField name="layout.applicationName" placeholder="Application Name" />
      <FormikInputField name="layout.applicationDescription" placeholder="Application Description" />
      <FormikInputField name="layout.boatPosition" placeholder="Boat Position" />
      <FormikInputField name="layout.latitude" placeholder="Latitude" />
      <FormikInputField name="layout.longitude" placeholder="Longitude" />
      <FormikInputField name="layout.boatSpeed" placeholder="Boat Speed" />
      <FormikInputField name="layout.currentSpeed" placeholder="Current Speed" />
      <FormikInputField name="layout.avarageSpeed" placeholder="Avarage Speed" />
      <FormikInputField name="layout.nearbyCapital" placeholder="Nearby Capital" />
      <FormikInputField name="layout.capitalName" placeholder="Capital Name" />
      <FormikInputField name="layout.population" placeholder="Population" />
    </DashboardFormsWrapper>
  );
};

export default LabelsSetupForm;
